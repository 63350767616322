<template>
  <div class="ViewFunctionTest" ref="boxm">

    <ul class="top-a padd-40">
      <li>距离(CM)</li>
      <li><PopularScience :text="'水平棱镜△'" :gjz="'shuipinglengjing'"></PopularScience></li>
      <li>BI/BO</li>
      <li> <PopularScience :text="'垂直棱镜△'" :gjz="'chuizhilengjing'"></PopularScience></li>
      <li>垂直眼位</li>
      <li> <PopularScience :text="'负融像功能BI'" :gjz="'bi'"></PopularScience></li>
      <li> <PopularScience :text="'正融像功能BO'" :gjz="'bo'"></PopularScience></li>
    </ul>
    <ul class="top-a">
      <li>600/500</li>
      <li>
        <el-select v-model="postData.S_OP_Json.S_OP_VF_HP_Far" placeholder="">
          <el-option
              v-for="item in a50"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
<!--        <input type="text" v-model="postData.S_OP_Json.S_OP_VF_HP_Far">-->
      </li>
      <li>
        <el-select v-model="postData.S_OP_Json.S_OP_VF_BIBOFar" placeholder="">
          <el-option
              v-for="item in BO"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </li>
      <li>
        <el-select v-model="postData.S_OP_Json.S_OP_VF_VP_Far" placeholder="">
          <el-option
              v-for="item in a50"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
<!--        <input type="text" v-model="postData.S_OP_Json.S_OP_VF_VP_Far">-->
      </li>
      <li>
        <el-select v-model="postData.S_OP_Json.S_OP_VF_BOBU_Far" placeholder="">
          <el-option
              v-for="item in LR"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </li>
      <li class="fx" @click="xzsx('.frxgn', 'biB1')">
        <el-select v-model="biA1val" placeholder="">
          <el-option
              v-for="item in biA1"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <span>/</span>
        <el-select v-model="postData.S_OP_Json.S_OP_VF_BIFarBDPoint" placeholder="">
          <el-option
              v-for="item in biB1"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <span>/</span>
        <el-select v-model="postData.S_OP_Json.S_OP_VF_BIFarRecPoint" placeholder="">
          <el-option
              v-for="item in biB1"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
<!--        <input type="text" v-model="postData.S_OP_Json.S_OP_VF_BIFarBDPoint">-->
<!--        <input type="text" v-model="postData.S_OP_Json.S_OP_VF_BIFarRecPoint">-->
      </li>
      <li class="fx">
        <el-select v-model="postData.S_OP_Json.S_OP_VF_BOFarBlurPoint" placeholder="">
          <el-option
              v-for="item in biB2"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
<!--        <input type="text" v-model="postData.S_OP_Json.S_OP_VF_BOFarBlurPoint">-->
        <span>/</span>
        <el-select v-model="postData.S_OP_Json.S_OP_VF_BOFarBDPoint" placeholder="">
          <el-option
              v-for="item in biB1"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
<!--        <input type="text" v-model="postData.S_OP_Json.S_OP_VF_BOFarBDPoint">-->
        <span>/</span>
        <el-select v-model="postData.S_OP_Json.S_OP_VF_BOFarRecPoint" placeholder="">
          <el-option
              v-for="item in biB1"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
<!--        <input type="text" v-model="postData.S_OP_Json.S_OP_VF_BOFarRecPoint">-->
      </li>
    </ul>
    <ul class="top-a">
      <li>40</li>
      <li>
        <el-select v-model="postData.S_OP_Json.S_OP_VF_HP_Near" placeholder="">
          <el-option
              v-for="item in a50"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
<!--        <input type="text" v-model="postData.S_OP_Json.S_OP_VF_HP_Near">-->
      </li>
      <li>
        <el-select v-model="postData.S_OP_Json.S_OP_VF_BIBONear" placeholder="">
          <el-option
              v-for="item in BO"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </li>
      <li>
        <el-select v-model="postData.S_OP_Json.S_OP_VF_VP_Near" placeholder="">
          <el-option
              v-for="item in a50"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
<!--        <input type="text" v-model="postData.S_OP_Json.S_OP_VF_VP_Near">-->
      </li>
      <li>
        <el-select v-model="postData.S_OP_Json.S_OP_VF_BOBU_Near" placeholder="">
          <el-option
              v-for="item in LR"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </li>
      <li class="fx">
        <el-select v-model="postData.S_OP_Json.S_OP_VF_BINearBlurPoint" placeholder="">
          <el-option
              v-for="item in biB2"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <span>/</span>
        <el-select v-model="postData.S_OP_Json.S_OP_VF_BINearBDPoint" placeholder="">
          <el-option
              v-for="item in biB1"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
<!--        <input v-model="postData.S_OP_Json.S_OP_VF_BINearBDPoint" type="text">-->
        <span>/</span>
        <el-select v-model="postData.S_OP_Json.S_OP_VF_BINearRecPoint" placeholder="">
          <el-option
              v-for="item in biB1"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
<!--        <input v-model="postData.S_OP_Json.S_OP_VF_BINearRecPoint" type="text">-->
      </li>
      <li class="fx">
        <el-select v-model="postData.S_OP_Json.S_OP_VF_BONearBlurPoint" placeholder="">
          <el-option
              v-for="item in biB2"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
<!--        <input type="text" v-model="postData.S_OP_Json.S_OP_VF_BONearBlurPoint">-->
        <span>/</span>
        <el-select v-model="postData.S_OP_Json.S_OP_VF_BONearBDPoint" placeholder="">
          <el-option
              v-for="item in biB1"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
<!--        <input type="text" v-model="postData.S_OP_Json.S_OP_VF_BONearBDPoint">-->
        <span>/</span>
        <el-select v-model="postData.S_OP_Json.S_OP_VF_BONearRecPoint" placeholder="">
          <el-option
              v-for="item in biB1"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
<!--        <input type="text" v-model="postData.S_OP_Json.S_OP_VF_BONearRecPoint">-->
      </li>
    </ul>
    <div class="top-b">
      <div class="border-b">
        <ul class="top-a">
          <li>40(+1D)</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_VF_HP_Near1D" placeholder="">
              <el-option
                  v-for="item in a50"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
<!--            <input  v-model="postData.S_OP_Json.S_OP_VF_HP_Near1D" type="text">-->
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_VF_BIBONear1D" placeholder="">
              <el-option
                  v-for="item in BO"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul class="top-a">
          <li>40(-1D)</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_VF_HP_Near_1D" placeholder="">
              <el-option
                  v-for="item in a50"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
<!--            <input type="text" v-model="postData.S_OP_Json.S_OP_VF_HP_Near_1D">-->
          </li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_VF_BIBONear_1D" placeholder="">
              <el-option
                  v-for="item in BO"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
      </div>
      <div class="hbl noneborder border-b" style="border-left: none;">
        <p class="xhx"><PopularScience :text="'梯度性AC/A:'" :gjz="'tiduxingaca'"></PopularScience></p>
        <input type="text" v-model="postData.S_OP_Json.S_OP_VF_ACAStep">
      </div>
      <div class="hbl border-b">
        <p class="xhx"><PopularScience :text="'计算性AC/A:'" :gjz="'jisuanxingaca'"></PopularScience></p>
        <input type="text" v-model="postData.S_OP_Json.S_OP_VF_ACA">
      </div>
    </div>
    <div class="bottoms padd-40">
      <div>
        <ul class="min">
          <li>调节功能检查</li>
          <li>OU</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_VF_ACC_OU" placeholder="">
              <el-option
                  v-for="item in gntj"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>OD</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_VF_ACC_OD" placeholder="">
              <el-option
                  v-for="item in gntj"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>OS</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_VF_ACC_OS" placeholder="">
              <el-option
                  v-for="item in gntj"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul class="min">
          <li class="xhx"><PopularScience :text="'调节幅度AMP'" :gjz="'tiaojifudu'"></PopularScience></li>
          <li><input v-model="postData.S_OP_Json.S_OP_VF_AMP_OU1" :class="srval==='S_OP_VF_AMP_OU1'?'xz':''" @focus="getgb(postData.S_OP_Json.S_OP_VF_AMP_OU1, 'S_OP_VF_AMP_OU1')" type="text">D</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_VF_AMP_OU2" placeholder="">
              <el-option
                  v-for="item in tjfd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li><input v-model="postData.S_OP_Json.S_OP_VF_AMP_OD1" :class="srval==='S_OP_VF_AMP_OD1'?'xz':''" @focus="getgb(postData.S_OP_Json.S_OP_VF_AMP_OD1, 'S_OP_VF_AMP_OD1')" type="text">D</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_VF_AMP_OD2" placeholder="">
              <el-option
                  v-for="item in tjfd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li><input type="text" v-model="postData.S_OP_Json.S_OP_VF_AMP_OS1" :class="srval==='S_OP_VF_AMP_OS1'?'xz':''" @focus="getgb(postData.S_OP_Json.S_OP_VF_AMP_OS1, 'S_OP_VF_AMP_OS1')">D</li>
          <li>
            <el-select v-model="postData.S_OP_Json.S_OP_VF_AMP_OS2" placeholder="">
              <el-option
                  v-for="item in tjfd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>

        <ul class="top-a">
          <li class="xhx"> <PopularScience :text="'负向调节NRA'" :gjz="'nra'"></PopularScience></li>
          <li class="wadd">
            <div class="trigger2" style="position: relative"></div>
<!--            <input v-model="postData.S_OP_Json.S_OP_VF_NRA_OU" type="text">-->
            <el-popover
                placement="bottom"
                width="200"
                trigger="click">
              <van-picker @change="sxbh" :columns="columns" />
              <el-button slot="reference" @click="sxdqkey = 'S_OP_VF_NRA_OU'">{{postData.S_OP_Json.S_OP_VF_NRA_OU}}</el-button>
            </el-popover>
          </li>
          <li class="wadd">
            <el-popover
                placement="bottom"
                width="200"
                trigger="click">
              <van-picker @change="sxbh" :columns="columns" />
              <el-button slot="reference" @click="sxdqkey = 'S_OP_VF_NRA_OD'">{{postData.S_OP_Json.S_OP_VF_NRA_OD}}</el-button>
            </el-popover>
<!--            <input v-model="postData.S_OP_Json.S_OP_VF_NRA_OD" type="text">-->
          </li>
          <li class="wadd">
            <el-popover
                placement="bottom"
                width="200"
                trigger="click">
              <van-picker @change="sxbh" :columns="columns" />
              <el-button slot="reference" @click="sxdqkey = 'S_OP_VF_NRA_OS'">{{postData.S_OP_Json.S_OP_VF_NRA_OS}}</el-button>
            </el-popover>
<!--            <input v-model="postData.S_OP_Json.S_OP_VF_NRA_OS" type="text">-->
          </li>
        </ul>
        <ul class="top-a">
          <li class="xhx"><PopularScience :text="'正向调节PRA'" :gjz="'pra'"></PopularScience></li>
          <li class="wadd">
            <el-popover
                placement="bottom"
                width="200"
                trigger="click">
              <van-picker @change="sxbh" :columns="PRA" />
              <el-button slot="reference" @click="sxdqkey = 'S_OP_VF_PRA_OU'">{{postData.S_OP_Json.S_OP_VF_PRA_OU}}</el-button>
            </el-popover>
<!--            <input v-model="postData.S_OP_Json.S_OP_VF_PRA_OU" type="text">-->
          </li>
          <li class="wadd">
            <el-popover
                placement="bottom"
                width="200"
                trigger="click">
              <van-picker @change="sxbh" :columns="PRA" />
              <el-button slot="reference" @click="sxdqkey = 'S_OP_VF_PRA_OD'">{{postData.S_OP_Json.S_OP_VF_PRA_OD}}</el-button>
            </el-popover>
<!--            <input v-model="postData.S_OP_Json.S_OP_VF_PRA_OD" type="text">-->
          </li>
          <li class="wadd">
            <el-popover
                placement="bottom"
                width="200"
                trigger="click">
              <van-picker @change="sxbh" :columns="PRA" />
              <el-button slot="reference" @click="sxdqkey = 'S_OP_VF_PRA_OS'">{{postData.S_OP_Json.S_OP_VF_PRA_OS}}</el-button>
            </el-popover>
<!--            <input v-model="postData.S_OP_Json.S_OP_VF_PRA_OS" type="text">-->
          </li>
        </ul>
        <ul class="top-a">
          <li class="xhx"> <PopularScience :text="'调节反应BCC'" :gjz="'bcc'"></PopularScience></li>
          <li class="wadd">
            <el-popover
                placement="bottom"
                width="200"
                trigger="click">
              <van-picker @change="sxbh" :columns="BCC" />
              <el-button slot="reference" @click="sxdqkey = 'S_OP_VF_BCC_OU'">{{postData.S_OP_Json.S_OP_VF_BCC_OU}}</el-button>
            </el-popover>
<!--            <input v-model="postData.S_OP_Json.S_OP_VF_BCC_OU" type="text">-->
          </li>
          <li class="wadd">
            <el-popover
                placement="bottom"
                width="200"
                trigger="click">
              <van-picker @change="sxbh" :columns="BCC" />
              <el-button slot="reference" @click="sxdqkey = 'S_OP_VF_BCC_OD'">{{postData.S_OP_Json.S_OP_VF_BCC_OD}}</el-button>
            </el-popover>
<!--            <input v-model="postData.S_OP_Json.S_OP_VF_BCC_OD" type="text">-->
          </li>
          <li class="wadd">
            <el-popover
                placement="bottom"
                width="200"
                trigger="click">
              <van-picker @change="sxbh" :columns="BCC" />
              <el-button slot="reference" @click="sxdqkey = 'S_OP_VF_BCC_OS'">{{postData.S_OP_Json.S_OP_VF_BCC_OS}}</el-button>
            </el-popover>
<!--            <input v-model="postData.S_OP_Json.S_OP_VF_BCC_OS" type="text">-->
          </li>
        </ul>
        <ul class="top-a">
          <li class="xhx border-b">动态检影MEM</li>
          <li class="wadd border-b"><input type="text" disabled></li>
          <li class="wadd border-b MEM">
            <span>
                <el-popover
                  placement="bottom"
                  width="200"
                  trigger="click">
                <van-picker @change="sxbh" :columns="BCC" />
                <el-button slot="reference" @click="sxdqkey = 'S_OP_VF_MEM_OD'">{{postData.S_OP_Json.S_OP_VF_MEM_OD}}</el-button>
              </el-popover>
            </span>
<!--            <input v-model="postData.S_OP_Json.S_OP_VF_MEM_OD" style="width: 80%" type="text">-->
            D</li>
          <li class="wadd border-b MEM">
            <span>
                <el-popover
                    placement="bottom"
                    width="200"
                    trigger="click">
                <van-picker @change="sxbh" :columns="BCC" />
                <el-button slot="reference" @click="sxdqkey = 'S_OP_VF_MEM_OS'">{{postData.S_OP_Json.S_OP_VF_MEM_OS}}</el-button>
              </el-popover>
            </span>
<!--            <input v-model="postData.S_OP_Json.S_OP_VF_MEM_OS" style="width: 80%" type="text">-->
            D</li>
        </ul>
      </div>
      <div style="margin-left: 5vw">
        <div class="bottoms-r-a NPC">
          <p class="xhx"><PopularScience :text="'集合近点NPC：'" :gjz="'npc'"></PopularScience></p>
          <el-popover
              placement="bottom"
              width="200"
              trigger="click">
            <van-picker @change="sxbh" :columns="NPC" />
            <el-button slot="reference" @click="sxdqkey = 'S_OP_VF_NPC1'">{{postData.S_OP_Json.S_OP_VF_NPC1}}</el-button>
          </el-popover>
<!--          <input v-model="postData.S_OP_Json.S_OP_VF_NPC1" type="text">-->
          <p>cm/</p>
          <el-popover
              placement="bottom"
              width="200"
              trigger="click">
            <van-picker @change="sxbh" :columns="NPC" />
            <el-button slot="reference" @click="sxdqkey = 'S_OP_VF_NPC2'">{{postData.S_OP_Json.S_OP_VF_NPC2}}</el-button>
          </el-popover>
<!--          <input v-model="postData.S_OP_Json.S_OP_VF_NPC2" type="text">-->
          <p>cm</p>
        </div>
        <div class="bottoms-r-b">
          <div><PopularScience :text="'调节灵活度：'" :gjz="'tiaojielingmindu'"></PopularScience></div>
          <div>
            <div class="bottoms-r-content OU">
              <p>OU</p>
              <el-popover
                  placement="bottom"
                  width="200"
                  trigger="click">
                <van-picker @change="sxbh" :columns="OU" />
                <el-button slot="reference" @click="sxdqkey = 'S_OP_VF_Flipper_OU'">{{postData.S_OP_Json.S_OP_VF_Flipper_OU}}</el-button>
              </el-popover>
<!--              <input v-model="postData.S_OP_Json.S_OP_VF_Flipper_OU" type="text">-->
              <p>(</p>
              <div class="zj">
                <el-select v-model="postData.S_OP_Json.S_OP_VF_Flipper_OU1" placeholder="">
                  <el-option
                      v-for="item in tjlhd"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <p>,</p>
              <div class="zj">
                <el-select v-model="postData.S_OP_Json.S_OP_VF_Flipper_OU2" placeholder="">
                  <el-option
                      v-for="item in tjlhd2"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <p>)</p>
            </div>
            <div class="bottoms-r-content OU">
              <p>OD</p>
              <el-popover
                  placement="bottom"
                  width="200"
                  trigger="click">
                <van-picker @change="sxbh" :columns="OU" />
                <el-button slot="reference" @click="sxdqkey = 'S_OP_VF_Flipper_OD'">{{postData.S_OP_Json.S_OP_VF_Flipper_OD}}</el-button>
              </el-popover>
<!--              <input v-model="postData.S_OP_Json.S_OP_VF_Flipper_OD" type="text">-->
              <p>(</p>
              <div class="zj">
                <el-select v-model="postData.S_OP_Json.S_OP_VF_Flipper_OD1" placeholder="">
                  <el-option
                      v-for="item in tjlhd"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <p>,</p>
              <div class="zj">
                <el-select v-model="postData.S_OP_Json.S_OP_VF_Flipper_OD2" placeholder="">
                  <el-option
                      v-for="item in tjlhd2"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <p>)</p>
            </div>
            <div class="bottoms-r-content OU">
              <p>OS</p>
              <el-popover
                  placement="bottom"
                  width="200"
                  trigger="click">
                <van-picker @change="sxbh" :columns="OU" />
                <el-button slot="reference" @click="sxdqkey = 'S_OP_VF_Flipper_OS'">{{postData.S_OP_Json.S_OP_VF_Flipper_OS}}</el-button>
              </el-popover>
<!--              <input v-model="postData.S_OP_Json.S_OP_VF_Flipper_OS" type="text">-->
              <p>(</p>
              <div class="zj">
                <el-select v-model="postData.S_OP_Json.S_OP_VF_Flipper_OS1" placeholder="">
                  <el-option
                      v-for="item in tjlhd"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <p>,</p>
              <div class="zj">
                <el-select v-model="postData.S_OP_Json.S_OP_VF_Flipper_OS2" placeholder="">
                  <el-option
                      v-for="item in tjlhd2"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <p>)</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        :visible.sync="showxgjc"
        :class="ishowjp?'fp':''"
        width="65%">
      <CorrelationTest></CorrelationTest>
    </el-dialog>

    <el-dialog
        title="双眼视异常辅助评估"
        :visible.sync="showpg"
        width="65%">
      <ViewFnAssess v-if="showpg"></ViewFnAssess>
    </el-dialog>

    <el-dialog
        title="视功能检查与分析"
        :visible.sync="showfx"
        width="75%">
      <el-tabs  v-model="activeName" @tab-click="handleClick" >
        <el-tab-pane label="Morgan标准值分析" name="first">
          <div class="fx-box">
            <p style="text-align: left;">Morgan标准值范围被广泛用来作为个体测量值比较的标准</p>
            <ul class="Morgan">
              <li>距离</li>
              <li>正常范围</li>
              <li>测量值</li>
              <li>结果</li>
            </ul>
            <div class="Morgan-content">
              <ul class="Morgan" v-if="postData.S_OP_Json.S_OP_VF_BIBOFar !== ''">
                <li>远距隐斜</li>
                <li>0-2外隐斜</li>
                <li>1{{postData.S_OP_Json.S_OP_VF_BIBOFar}}</li>
                <li :class="postData.S_OP_Json.S_OP_VF_BIBOFar !=='BI'?'cwcolor':''">{{postData.S_OP_Json.S_OP_VF_BIBOFar=='BI'?'正常':'内隐斜'}}</li>
              </ul>
              <ul class="Morgan" v-if="postData.S_OP_Json.S_OP_VF_BIBONear !== ''">
                <li>40厘米隐斜</li>
                <li>0-6外隐斜</li>
                <li>1{{postData.S_OP_Json.S_OP_VF_BIBONear}}</li>
                <li :class="postData.S_OP_Json.S_OP_VF_BIBONear !=='BI'?'cwcolor':''">{{postData.S_OP_Json.S_OP_VF_BIBONear=='BI'?'正常':'内隐斜'}}</li>
              </ul>
              <ul class="Morgan" >
                <li>远距BI模糊</li>
                <li>X</li>
                <li>X</li>
                <li>正常</li>
              </ul>
              <ul class="Morgan" v-if="postData.S_OP_Json.S_OP_VF_BIFarBDPoint !== ''">
                <li>远距BI破裂</li>
                <li>5-9</li>
                <li>{{postData.S_OP_Json.S_OP_VF_BIFarBDPoint}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BIFarBDPoint < 5">{{postData.S_OP_Json.S_OP_VF_BIFarBDPoint < 5?'低':''}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BIFarBDPoint > 9">{{postData.S_OP_Json.S_OP_VF_BIFarBDPoint > 9?'高':''}}</li>
                <li v-if="postData.S_OP_Json.S_OP_VF_BIFarBDPoint >= 5 && postData.S_OP_Json.S_OP_VF_BIFarBDPoint <= 9">{{(postData.S_OP_Json.S_OP_VF_BIFarBDPoint >= 5 && postData.S_OP_Json.S_OP_VF_BIFarBDPoint <= 9) ?'正常':''}}</li>
              </ul>
              <ul class="Morgan" v-if="postData.S_OP_Json.S_OP_VF_BIFarRecPoint !== ''">
                <li>远距BI恢复</li>
                <li>3-5</li>
                <li>{{postData.S_OP_Json.S_OP_VF_BIFarRecPoint}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BIFarRecPoint < 3">{{postData.S_OP_Json.S_OP_VF_BIFarRecPoint < 3?'低':''}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BIFarRecPoint > 5">{{postData.S_OP_Json.S_OP_VF_BIFarRecPoint > 5?'高':''}}</li>
                <li v-if="postData.S_OP_Json.S_OP_VF_BIFarRecPoint >= 3 && postData.S_OP_Json.S_OP_VF_BIFarRecPoint <= 5">{{(postData.S_OP_Json.S_OP_VF_BIFarRecPoint >= 3 && postData.S_OP_Json.S_OP_VF_BIFarRecPoint <= 5) ?'正常':''}}</li>
              </ul>

              <ul class="Morgan" v-if="postData.S_OP_Json.S_OP_VF_BOFarBlurPoint !== ''">
                <li>远距BO模糊</li>
                <li>7-11</li>
                <li>{{postData.S_OP_Json.S_OP_VF_BOFarBlurPoint}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BOFarBlurPoint < 7 || postData.S_OP_Json.S_OP_VF_BOFarBlurPoint=='X'">低</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BOFarBlurPoint > 11">{{postData.S_OP_Json.S_OP_VF_BOFarBlurPoint > 11?'高':''}}</li>
                <li v-if="postData.S_OP_Json.S_OP_VF_BOFarBlurPoint >= 7 && postData.S_OP_Json.S_OP_VF_BOFarBlurPoint <= 11">{{(postData.S_OP_Json.S_OP_VF_BOFarBlurPoint >= 7 && postData.S_OP_Json.S_OP_VF_BOFarBlurPoint <= 11) ?'正常':''}}</li>
              </ul>
              <ul class="Morgan" v-if="postData.S_OP_Json.S_OP_VF_BOFarBDPoint !== ''">
                <li>远距BO破裂</li>
                <li>15-23</li>
                <li>{{postData.S_OP_Json.S_OP_VF_BOFarBDPoint}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BOFarBDPoint < 15">{{postData.S_OP_Json.S_OP_VF_BOFarBDPoint < 15?'低':''}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BOFarBDPoint > 23">{{postData.S_OP_Json.S_OP_VF_BOFarBDPoint > 23?'高':''}}</li>
                <li v-if="postData.S_OP_Json.S_OP_VF_BOFarBDPoint >= 15 && postData.S_OP_Json.S_OP_VF_BOFarBDPoint <= 23">{{(postData.S_OP_Json.S_OP_VF_BOFarBDPoint >= 15 && postData.S_OP_Json.S_OP_VF_BOFarBDPoint <= 23) ?'正常':''}}</li>
              </ul>
              <ul class="Morgan" v-if="postData.S_OP_Json.S_OP_VF_BOFarRecPoint !== ''">
                <li>远距BO恢复</li>
                <li>8-12</li>
                <li>{{postData.S_OP_Json.S_OP_VF_BOFarRecPoint}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BOFarRecPoint < 8">{{postData.S_OP_Json.S_OP_VF_BOFarRecPoint < 8?'低':''}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BOFarRecPoint > 12">{{postData.S_OP_Json.S_OP_VF_BOFarRecPoint > 12?'高':''}}</li>
                <li v-if="postData.S_OP_Json.S_OP_VF_BOFarRecPoint >= 8 && postData.S_OP_Json.S_OP_VF_BOFarRecPoint <= 12">{{(postData.S_OP_Json.S_OP_VF_BOFarRecPoint >= 8 && postData.S_OP_Json.S_OP_VF_BOFarRecPoint <= 12) ?'正常':''}}</li>
              </ul>

              <ul class="Morgan" v-if="postData.S_OP_Json.S_OP_VF_BINearBlurPoint !== ''">
                <li>40厘米BI模糊</li>
                <li>11-15/X</li>
                <li>{{postData.S_OP_Json.S_OP_VF_BINearBlurPoint}}</li>
                <li v-if="postData.S_OP_Json.S_OP_VF_BINearBlurPoint == 'X'">正常</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BINearBlurPoint < 5 && postData.S_OP_Json.S_OP_VF_BINearBlurPoint != 'X'">{{postData.S_OP_Json.S_OP_VF_BINearBlurPoint < 11?'低':''}}</li>
                <li v-if="postData.S_OP_Json.S_OP_VF_BINearBlurPoint >= 3 && postData.S_OP_Json.S_OP_VF_BINearBlurPoint <= 5">{{(postData.S_OP_Json.S_OP_VF_BINearBlurPoint >= 11 && postData.S_OP_Json.S_OP_VF_BINearBlurPoint <= 15) ?'正常':''}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BINearBlurPoint > 15 && postData.S_OP_Json.S_OP_VF_BINearBlurPoint != 'X'">{{postData.S_OP_Json.S_OP_VF_BINearBlurPoint > 15?'高':''}}</li>
              </ul>
              <ul class="Morgan" v-if="postData.S_OP_Json.S_OP_VF_BINearBDPoint!==''">
                <li>40厘米BI破裂</li>
                <li>19-23</li>
                <li>{{postData.S_OP_Json.S_OP_VF_BINearBDPoint}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BINearBDPoint < 19">{{postData.S_OP_Json.S_OP_VF_BINearBDPoint < 19?'低':''}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BINearBDPoint > 23">{{postData.S_OP_Json.S_OP_VF_BINearBDPoint > 23?'高':''}}</li>
                <li v-if="postData.S_OP_Json.S_OP_VF_BINearBDPoint >= 19 && postData.S_OP_Json.S_OP_VF_BINearBDPoint <= 23">{{(postData.S_OP_Json.S_OP_VF_BINearBDPoint >= 19 && postData.S_OP_Json.S_OP_VF_BINearBDPoint <= 23) ?'正常':''}}</li>
              </ul>
              <ul class="Morgan" v-if="postData.S_OP_Json.S_OP_VF_BINearRecPoint!==''">
                <li>40厘米BI恢复</li>
                <li>10-16</li>
                <li>{{postData.S_OP_Json.S_OP_VF_BINearRecPoint}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BINearRecPoint < 10">{{postData.S_OP_Json.S_OP_VF_BINearRecPoint < 10?'低':''}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BINearRecPoint > 16">{{postData.S_OP_Json.S_OP_VF_BINearRecPoint > 16?'高':''}}</li>
                <li v-if="postData.S_OP_Json.S_OP_VF_BINearRecPoint >= 10 && postData.S_OP_Json.S_OP_VF_BINearRecPoint <= 16">{{(postData.S_OP_Json.S_OP_VF_BINearRecPoint >= 10 && postData.S_OP_Json.S_OP_VF_BINearRecPoint <= 16) ?'正常':''}}</li>
              </ul>

              <ul class="Morgan" v-if="postData.S_OP_Json.S_OP_VF_BONearBlurPoint!==''">
                <li>40厘米BO模糊</li>
                <li>14-38/X</li>
                <li>{{postData.S_OP_Json.S_OP_VF_BONearBlurPoint}}</li>
                <li v-if="postData.S_OP_Json.S_OP_VF_BONearBlurPoint == 'X'">正常</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BONearBlurPoint < 14 && postData.S_OP_Json.S_OP_VF_BONearBlurPoint != 'X'">{{postData.S_OP_Json.S_OP_VF_BONearBlurPoint < 14?'低':''}}</li>
                <li v-if="postData.S_OP_Json.S_OP_VF_BONearBlurPoint >= 14 && postData.S_OP_Json.S_OP_VF_BONearBlurPoint <= 38">{{(postData.S_OP_Json.S_OP_VF_BONearBlurPoint >= 14 && postData.S_OP_Json.S_OP_VF_BONearBlurPoint <= 38) ?'正常':''}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BONearBlurPoint > 38 && postData.S_OP_Json.S_OP_VF_BONearBlurPoint != 'X'">{{postData.S_OP_Json.S_OP_VF_BONearBlurPoint > 38?'高':''}}</li>
              </ul>
              <ul class="Morgan" v-if="postData.S_OP_Json.S_OP_VF_BONearBDPoint !== ''">
                <li>40厘米BO破裂</li>
                <li>18-24</li>
                <li>{{postData.S_OP_Json.S_OP_VF_BONearBDPoint}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BONearBDPoint < 18">{{postData.S_OP_Json.S_OP_VF_BONearBDPoint < 18?'低':''}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BONearBDPoint > 24">{{postData.S_OP_Json.S_OP_VF_BONearBDPoint > 24?'高':''}}</li>
                <li v-if="postData.S_OP_Json.S_OP_VF_BONearBDPoint >= 18 && postData.S_OP_Json.S_OP_VF_BONearBDPoint <= 24">{{(postData.S_OP_Json.S_OP_VF_BONearBDPoint >= 18 && postData.S_OP_Json.S_OP_VF_BONearBDPoint <= 24) ?'正常':''}}</li>
              </ul>
              <ul class="Morgan" v-if="postData.S_OP_Json.S_OP_VF_BONearRecPoint !== ''">
                <li>40厘米BO恢复</li>
                <li>7-15</li>
                <li>{{postData.S_OP_Json.S_OP_VF_BONearRecPoint}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BONearRecPoint < 7">{{postData.S_OP_Json.S_OP_VF_BONearRecPoint < 7?'低':''}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_BONearRecPoint > 15">{{postData.S_OP_Json.S_OP_VF_BONearRecPoint > 15?'高':''}}</li>
                <li v-if="postData.S_OP_Json.S_OP_VF_BONearRecPoint >= 7 && postData.S_OP_Json.S_OP_VF_BONearRecPoint <= 15">{{(postData.S_OP_Json.S_OP_VF_BONearRecPoint >= 7 && postData.S_OP_Json.S_OP_VF_BONearRecPoint <= 15) ?'正常':''}}</li>
              </ul>

              <ul class="Morgan" v-if="postData.S_OP_Json.S_OP_VF_NRA_OU !== ''">
                <li>NRA</li>
                <li>2.25-2.50</li>
                <li>{{postData.S_OP_Json.S_OP_VF_NRA_OU}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_NRA_OU < 2.25">{{postData.S_OP_Json.S_OP_VF_NRA_OU < 2.25?'低':''}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_NRA_OU > 2.5">{{postData.S_OP_Json.S_OP_VF_NRA_OU > 2.5?'高':''}}</li>
                <li v-if="postData.S_OP_Json.S_OP_VF_NRA_OU >= 2.25 && postData.S_OP_Json.S_OP_VF_NRA_OU <= 2.5">{{(postData.S_OP_Json.S_OP_VF_NRA_OU >= 2.25 && postData.S_OP_Json.S_OP_VF_NRA_OU <= 2.5) ?'正常':''}}</li>
              </ul>
              <ul class="Morgan" v-if="postData.S_OP_Json.S_OP_VF_PRA_OU !== ''">
                <li>PRA</li>
                <li> >=-2.50 </li>
                <li>{{postData.S_OP_Json.S_OP_VF_PRA_OU}}</li>
                <li class="cwcolor" v-if="Math.abs(postData.S_OP_Json.S_OP_VF_PRA_OU) < 2.5">{{Math.abs(postData.S_OP_Json.S_OP_VF_PRA_OU)< 2.5?'低':''}}</li>
                <li v-if="Math.abs(postData.S_OP_Json.S_OP_VF_PRA_OU) >= 2.5">正常</li>
              </ul>

              <ul class="Morgan" v-if="postData.S_OP_Json.S_OP_VF_ACAStep !== ''">
                <li>梯度性AC/A比率 </li>
                <li>3-5</li>
                <li>{{postData.S_OP_Json.S_OP_VF_ACAStep}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_ACAStep < 3">{{postData.S_OP_Json.S_OP_VF_ACAStep < 3?'低':''}}</li>
                <li class="cwcolor" v-if="postData.S_OP_Json.S_OP_VF_ACAStep > 5">{{postData.S_OP_Json.S_OP_VF_ACAStep > 5?'高':''}}</li>
                <li v-if="postData.S_OP_Json.S_OP_VF_ACAStep >= 3 && postData.S_OP_Json.S_OP_VF_ACAStep <= 5">{{(postData.S_OP_Json.S_OP_VF_ACAStep >= 3 && postData.S_OP_Json.S_OP_VF_ACAStep <= 5) ?'正常':''}}</li>
              </ul>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane label="Sheard氏准则" name="second" style="text-align: left;">
          <div>Sheard是一种有效的眼部症状预测临床经验公式，特别是外隐斜</div>
          <div class="gsbox" v-html="Sheard"></div>
          <div class="gsbox" v-html="Sheard40"></div>
        </el-tab-pane>

        <el-tab-pane label="1:1准则" name="third" style="text-align: left;">
          <div>本规则仅能应用于内隐斜，BI恢复点应该至少同内隐斜一样大</div>
          <div class="gsbox" v-html="bl11"></div>
          <div class="gsbox" v-html="bl1140"></div>
        </el-tab-pane>
        <el-tab-pane label="Percival准则" name="fourth" style="text-align: left;">
          <div class="gsbox" v-html="percival"></div>
          <div class="gsbox" v-html="percival40"></div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <el-dialog
        title=""
        :visible.sync="clshow"
        width="921px">
      <CLZJ></CLZJ>
    </el-dialog>

    <ul class="fn">
      <li><el-button @click="$router.back(-1)">返回</el-button></li>
      <li><el-button @click="showxgjc = true">相关检查</el-button></li>
<!--      <li><el-button>图表</el-button></li>-->
      <li><el-button @click="showfx = true">分析</el-button></li>
      <li><el-button @click="showpg = true">评估</el-button></li>
      <li><el-button @click="getpgjg">处理</el-button></li>
      <li><el-button @click="save">保存</el-button></li>
    </ul>

    <KEYHome v-if="showkeys" :keyval="keyval" @changeval="changeval"></KEYHome>
  </div>
</template>

 <script>
import { Picker } from 'vant';
export default {
  name: "ViewFunctionTest",
  data() {
    return {
      showdrsb: false,
      showkeys: false,
      issr: false,
      srval: '',
      keyval: '',


      ishowjp: false,

      datas: '',
      showxgjc: false,
      showpg: false,
      options: [
        {name: '无明显受限',value:0, isshow: false},
        {name: '眼球震颤',value:1, isshow: false},
        {name: '外屏障碍',value:2, isshow: false},
        {name: '内收障碍',value:3, isshow: false},
        {name: '上转障碍',value:4, isshow: false},
        {name: '下转障碍',value:5, isshow: false},
        {name: '手动填写',value:6, isshow: false}
      ],
      value: '',
      tjlhd: [
        {
          value: '20/30',
          label: '20/30'
        },
        {
          value: '20/40',
          label: '20/40'
        },
        {
          value: '20/50',
          label: '20/50'
        }
      ],
      tjlhd2: [
        {
          value: '±1.00',
          label: '±1.00'
        },
        {
          value: '±1.50',
          label: '±1.50'
        },
        {
          value: '±2.00',
          label: '±2.00'
        },
        {
          value: '±2.50',
          label: '±2.50'
        }
      ],
      BO: [
        {
          value: 'BI',
          label: 'BI'
        },
        {
          value: 'BO',
          label: 'BO'
        }
      ],
      LR: [
        {
          value: 'R/L',
          label: 'R/L'
        },
        {
          value: 'L/R',
          label: 'L/R'
        }
      ],
      gntj: [
        {
          value: '20/25',
          label: '20/25'
        },
        {
          value: '20/30',
          label: '20/30'
        },
        {
          value: '20/40',
          label: '20/40'
        },
        {
          value: '20/50',
          label: '20/50'
        },
        {
          value: '20/60',
          label: '20/60'
        },
        {
          value: '20/70',
          label: '20/70'
        },
        {
          value: '20/80',
          label: '20/80'
        },
        {
          value: '20/90',
          label: '20/90'
        },
        {
          value: '20/100',
          label: '20/100'
        },
        {
          value: '20/110',
          label: '20/110'
        },
        {
          value: '20/120',
          label: '20/120'
        },
        {
          value: '20/130',
          label: '20/130'
        },
        {
          value: '20/140',
          label: '20/140'
        },
        {
          value: '20/150',
          label: '20/150'
        },
        {
          value: '20/160',
          label: '20/160'
        },
        {
          value: '20/170',
          label: '20/170'
        },
        {
          value: '20/180',
          label: '20/180'
        },
        {
          value: '20/190',
          label: '20/190'
        },
        {
          value: '20/200',
          label: '20/200'
        }
      ],
      tjfd: [
        {
          value: '推进法',
          label: '推进法'
        },
        {
          value: '调节法',
          label: '调节法'
        },
        {
          value: ' ',
          label: ' '
        }
      ],
      postData: {
        S_OP_ChooseId: "",
        S_OP_CustomerId: "",
        S_OP_ExpertId: "00000000-0000-0000-0000-000000000000",
        S_OP_HealthCheckId: "00000000-0000-0000-0000-000000000000",
        S_OP_ID: "00000000-0000-0000-0000-000000000000",
        S_OP_Json: {
          S_OP_VF_ACA: "",
          S_OP_VF_ACAStep: "",
          S_OP_VF_ACAStep_extra: "",
          S_OP_VF_ACC_OD: "",
          S_OP_VF_ACC_OS: "",
          S_OP_VF_ACC_OU: "",
          S_OP_VF_AMP_OD1: "",
          S_OP_VF_AMP_OD2: "",
          S_OP_VF_AMP_OS1: "",
          S_OP_VF_AMP_OS2: "",
          S_OP_VF_AMP_OU1: "",
          S_OP_VF_AMP_OU2: "",
          S_OP_VF_Age: "",
          S_OP_VF_Analysis11: "",
          S_OP_VF_AnalysisMorgan: "",
          S_OP_VF_AnalysisPercival: "",
          S_OP_VF_AnalysisSheard: "",
          S_OP_VF_BCC_OD: "",
          S_OP_VF_BCC_OS: "",
          S_OP_VF_BCC_OU: "",
          S_OP_VF_BIBOFar: "",
          S_OP_VF_BIBONear: "",
          S_OP_VF_BIBONear1D: "",
          S_OP_VF_BIBONear_1D: "",
          S_OP_VF_BIFarBDPoint: "",
          S_OP_VF_BIFarBlurPoint: "",
          S_OP_VF_BIFarRecPoint: "",
          S_OP_VF_BINearBDPoint: "",
          S_OP_VF_BINearBlurPoint: "",
          S_OP_VF_BINearRecPoint: "",
          S_OP_VF_BOBU_Far: "",
          S_OP_VF_BOBU_Near: "",
          S_OP_VF_BOFarBDPoint: "",
          S_OP_VF_BOFarBlurPoint: "",
          S_OP_VF_BOFarRecPoint: "",
          S_OP_VF_BONearBDPoint: "",
          S_OP_VF_BONearBlurPoint: "",
          S_OP_VF_BONearRecPoint: "",
          S_OP_VF_ChooseId: "",
          S_OP_VF_DC_OD: "",
          S_OP_VF_DC_OS: "",
          S_OP_VF_DS_OD: "",
          S_OP_VF_DS_OS: "",
          S_OP_VF_Deal: "",
          S_OP_VF_Diagnosis: ",",
          S_OP_VF_EditTime: "",
          S_OP_VF_Flipper_OD: "",
          S_OP_VF_Flipper_OD1: "",
          S_OP_VF_Flipper_OD2: "",
          S_OP_VF_Flipper_OS: "",
          S_OP_VF_Flipper_OS1: "",
          S_OP_VF_Flipper_OS2: "",
          S_OP_VF_Flipper_OU: "",
          S_OP_VF_Flipper_OU1: "",
          S_OP_VF_Flipper_OU2: "",
          S_OP_VF_HP_Far: "",
          S_OP_VF_HP_Near: "",
          S_OP_VF_HP_Near1D: "",
          S_OP_VF_HP_Near_1D: "",
          S_OP_VF_MEM_OD: "",
          S_OP_VF_MEM_OS: "",
          S_OP_VF_NPC1: "",
          S_OP_VF_NPC2: "",
          S_OP_VF_NRA_OD: "",
          S_OP_VF_NRA_OS: "",
          S_OP_VF_NRA_OU: "",
          S_OP_VF_PD: "",
          S_OP_VF_PRA_OD: "",
          S_OP_VF_PRA_OS: "",
          S_OP_VF_PRA_OU: "",
          S_OP_VF_Remark: "",
          S_OP_VF_Say: "",
          S_OP_VF_Stereopsis_Far: "",
          S_OP_VF_Stereopsis_Near: "",
          S_OP_VF_Stereopsis_Tool: "",
          S_OP_VF_Train: "",
          S_OP_VF_VA_OD: "",
          S_OP_VF_VA_OS: "",
          S_OP_VF_VP_Far: "",
          S_OP_VF_VP_Near: "",
          S_OP_VF_Worth4_Far: "",
          S_OP_VF_Worth4_Near: "",
          S_OP_VF_X_OD: "",
          S_OP_VF_X_OS: "",
          S_OP_VF_isDone: true,
          S_OP_VF_leadingEye: ""
        },
        S_OP_Model: "S_OP_VisionFunction180717",
        S_OP_Time: "",
        S_OP_Type: "VisionFunction",
        S_OP_XML: ""
      },

      biA1val: 'X',
      biA1: [
        {
          value: 'X',
          label: 'X'
        }
      ],
      biB1: [],
      biB2: [],
      biB2val: 'X',
      a50: [],
      lastMobileSelect2: '',
      columns: [
        // 第一列
        {
          values: ['+'],
          defaultIndex: 2,
        },
        // 第二列
        {
          values: ['0', '1', '2', '3', '4', '5'],
          defaultIndex: 1,
        },
        {
          values: ['.00', '.25', '.50', '.75'],
          defaultIndex: 3,
        },
      ],
      sxdqkey: '',
      PRA: [
        {
          values: ['-'],
          defaultIndex: 2,
        },
        {
          values: ['0', '1', '2', '3', '4', '5', '6', '7', '8'],
          defaultIndex: 1,
        },
        {
          values: ['.00', '.25', '.50', '.75'],
          defaultIndex: 3,
        },
      ],
      BCC: [
        {
          values: ['+','-'],
          defaultIndex: 2,
        },
        {
          values: ['0', '1', '2'],
          defaultIndex: 1,
        },
        {
          values: ['.00', '.25', '.50', '.75'],
          defaultIndex: 3,
        },
      ],
      NPC: [],
      OU: [],

      showfx: false,
      activeName: 'first',
      clshow: false,
      pgjg: [] //评估结果
    }
  },
  created() {
    this.cshTime('EC_eyesPosition')
    let biB1arr = [],item = {}
    for (let i = -50; i < 51; i++) {
      biB1arr.push({
        id: i,
        value: i
      })
    }
    item.data = [...biB1arr]
    this.biB1 = [...biB1arr]
    biB1arr = []
    for (let i = -50; i < 51; i++) {
      biB1arr.push({
        value: i,
        label: i
      })
      if (i == -1) {
        biB1arr.push({
          value: "X",
          label: "X"
        })
      }
    }
    this.biB2 = [...biB1arr]
    biB1arr = []
    for (let i = 0; i < 51; i++) {
      biB1arr.push({
        value: i,
        label: i
      })
    }
    this.a50 = [...biB1arr]
    biB1arr = []
    for (let i = 0; i <= 50; i++) {
      biB1arr.push(i)
    }
    this.NPC = [
      {
        values: [...biB1arr],
        defaultIndex: 2,
      },
      {
        values: [".0", ".5"],
        defaultIndex: 1,
      },
    ]
    biB1arr = ['']
    for (let i = 0; i < 21; i++) {
      biB1arr.push(i)
    }
    this.OU = [
      {
        values: [...biB1arr],
        defaultIndex: 2,
      },
      {
        values: ['',"+", "-", "±"],
        defaultIndex: 1,
      },
    ]
    biB1arr = null

    this._api.eyeTest.getViewFnTest()
    .then(res => {
      if (res.GetResult) {
        res.GetResult.S_OP_Json = this._clJson(res.GetResult.S_OP_Json)
        //console.log(res.GetResult.S_OP_Json)
        this.postData = res.GetResult
      }else {
        this.postData.S_OP_ChooseId = this.$store.state.physicianVisits.xzConsulting
        this.postData.S_OP_CustomerId = this.$store.state.users.CsUser.CustomerId
        this.postData.S_OP_ExpertId = this.$store.state.users.employees.ExpertId || '00000000-0000-0000-0000-000000000000'
        this.postData.S_OP_Json.S_OP_VF_ChooseId = this.$store.state.physicianVisits.xzConsulting
      }
    })
  },
  watch: {
    postData: {
      handler(n, o) {
        this.$store.commit('eyeTest/upViewFnTest', this.postData)
      },
      deep: true,
      immediate: true
    },
    "$store.state.isShow.keyh": {
      handler(n, o) {
        // console.dir(document.body.offsetHeight)
        // console.dir(this.$refs.boxs)
        if (n > document.body.offsetHeight - 290) {
          this.$refs.boxm.scrollTop = this.$refs.boxm.scrollTop + (n - (document.body.offsetHeight - 290)) + 30
        }
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          this.ishowjp = true
        }

      }
    },
  },

  computed: {
    Sheard() {
      let sp = this.postData.S_OP_Json.S_OP_VF_HP_Far
      if (this.postData.S_OP_Json.S_OP_VF_BIBOFar == "BO") {
            let pl = this.postData.S_OP_Json.S_OP_VF_BIFarBDPoint
        if (pl == '') return ''
        if ( sp*2 > pl) {
          return "<p>★6米处不符合Sheard法则(P>0)</p>" +
              "<p>①6米处的BI破裂点(%.2f△)不足隐斜的两倍(%.2fEXO)</p>" +
              `<p>根据公式P=2/3D-1/3R计算，P=${((2/3)*sp - (1/3)*pl).toFixed(2)}BO</p>`
        }else if (sp*2 <= pl) {
          return "<p>★6米处符合Sheard法则(P<=0)</p>" +
              `<p>①6米处的BI破裂点(${pl}△)满足隐斜的两倍(1.00EXO)</p>`
        }
      }else if (this.postData.S_OP_Json.S_OP_VF_BIBOFar == "BI") {
        let pl = this.postData.S_OP_Json.S_OP_VF_BOFarBlurPoint
        if ( sp*2 > pl) {
          return "<p>★6米处不符合Sheard法则(P>0)</p>" +
              "<p>①6米处的BO模糊点(%.2f△)不足隐斜的两倍(%.2fEXO)</p>" +
              `<p>根据公式P=2/3D-1/3R计算，P=${((2/3)*sp - (1/3)*pl).toFixed(2)}BI</p>`
        }else if (sp*2 <= pl || pl == 'X') {
          return "<p>★6米处符合Sheard法则(P<=0)</p>" +
              `<p>①6米处的BO模糊点(${pl}△)满足隐斜的两倍(1.00EXO)</p>`
        }
      }
    },
    Sheard40() {
      let sp = this.postData.S_OP_Json.S_OP_VF_HP_Near
      if (this.postData.S_OP_Json.S_OP_VF_BIBONear == "BO") {
        let pl = this.postData.S_OP_Json.S_OP_VF_BINearBDPoint
        if (pl == '') return ''
        if ( sp*2 > pl) {
          return "<p>★40厘米处不符合Sheard法则(P>0)</p>" +
              "<p>①40厘米处的BI破裂点(%.2f△)不足隐斜的两倍(%.2fEXO)</p>" +
              `<p>根据公式P=2/3D-1/3R计算，P=${((2/3)*sp - (1/3)*pl).toFixed(2)}BO</p>`
        }else if (sp*2 <= pl) {
          return "<p>★40厘米处符合Sheard法则(P<=0)</p>" +
              `<p>①40厘米处的BI破裂点(${pl}△)满足隐斜的两倍(1.00EXO)</p>`
        }
      }else if (this.postData.S_OP_Json.S_OP_VF_BIBOFar == "BI") {
        let pl = this.postData.S_OP_Json.S_OP_VF_BONearBlurPoint
        if ( sp*2 > pl) {
          return "<p>★40厘米处不符合Sheard法则(P>0)</p>" +
              "<p>①40厘米处的BO模糊点(%.2f△)不足隐斜的两倍(%.2fEXO)</p>" +
              `<p>根据公式P=2/3D-1/3R计算，P=${((2/3)*sp - (1/3)*pl).toFixed(2)}BI</p>`
        }else if (sp*2 <= pl || pl == 'X') {
          return "<p>★40厘米处符合Sheard法则(P<=0)</p>" +
              `<p>①40厘米处的BO模糊点(${pl}△)满足隐斜的两倍(1.00EXO)</p>`
        }
      }
    },

    bl11() {
      let sp = this.postData.S_OP_Json.S_OP_VF_HP_Far
      if (this.postData.S_OP_Json.S_OP_VF_BIBOFar == "BO") {
        let hf = this.postData.S_OP_Json.S_OP_VF_BIFarRecPoint
        if (hf == '') return ''
        if (hf < sp) {
           return "<p>★远距为内隐斜，可使用1:1规则。</p>" +
               "<p>远距不符合1:1规则，BI恢复点小于内隐斜。</p>" +
               `<p>BO棱镜=(内隐斜-BI恢复值)/2;BO棱镜=(%.2f-%.2f)/2;BO棱镜=${((sp - hf)/2).toFixed(2)}。</p>`
               +"<p>若计算出为负值或零则表示不需要棱镜</p>"
        }else if (hf >= sp) {
           return "<p>★远距为内隐斜，可使用1:1规则。</p>" +
               "远距符合1:1规则，BI恢复点大于或等于内隐斜。"
        }
      }else if (this.postData.S_OP_Json.S_OP_VF_BIBOFar == "BI") {
        return "★远距不是内隐斜，不能使用1:1规则。"
      }
    },
    bl1140() {
      let sp = this.postData.S_OP_Json.S_OP_VF_HP_Near
      if (this.postData.S_OP_Json.S_OP_VF_BIBOFar == "BO") {
        let hf = this.postData.S_OP_Json.S_OP_VF_BINearRecPoint
        if (hf == '') return ''
        if (hf < sp) {
          return "<p>★40厘米为内隐斜，可使用1:1规则。</p>" +
              "<p>近距不符合1:1规则，BI恢复点小于内隐斜。</p>" +
              `<p>BO棱镜=(内隐斜-BI恢复值)/2;BO棱镜=(%.2f-%.2f)/2;BO棱镜=${((sp - hf)/2).toFixed(2)}。</p>`
          +"<p>若计算出为负值或零则表示不需要棱镜</p>"
        }else if (hf >= sp) {
          return "<p>★40厘米为内隐斜，可使用1:1规则。</p>" +
              "近距符合1:1规则，BI恢复点大于或等于内隐斜。"
        }
      }else if (this.postData.S_OP_Json.S_OP_VF_BIBOFar == "BI") {
        return "★40厘米不是内隐斜，不能使用1:1规则。"
      }
    },

    percival() {
      let bo =  this.postData.S_OP_Json.S_OP_VF_BOFarBlurPoint,
          bi = this.postData.S_OP_Json.S_OP_VF_BIFarBDPoint,
          arr = '',
          jsxac = this.postData.S_OP_Json.S_OP_VF_ACA

      if (bo == "X") {
        return "<p>★6米处符合Percival法则(P<=0)</p>" +
            "①6米处的BO模糊点(%.2f△)范围大于等于BI模糊点(%@△)的一半"
      }

      if (bo== '' && bi == '') return ''

      if (bo != '' && bi != '') {
        bo > bi?arr=[bo, bi]:arr=[bi, bo]
      }else {
        return ''
      }
      let p = ((arr[0]*(1/3))-(arr[1]*(2/3))).toFixed(2)
      if (p > 0) {
        return "<p>★6米处不符合Percival法则(P>0)</p>" +
            `<p>①根据公式P=1/3G-2/3L计算，P=1/3×${arr[0]}-2/3×${arr[1]},P(分解)=${p}BO</p>`
      }else {
        return "<p>★6米处符合Percival法则(P<=0)</p>" +
            "<p>6米处的BO模糊点(%.2f△)范围大于等于BI模糊点(%@△)的一半</p>"
      }

    },
    percival40() {
      let bo =  this.postData.S_OP_Json.S_OP_VF_BINearBlurPoint,
          bi = this.postData.S_OP_Json.S_OP_VF_BONearBlurPoint,
          // bip = this.postData.S_OP_Json.S_OP_VF_BINearBDPoint
          arr = ''
      if (bo == '' && bi == '') return ''
      if (bo == "X" && bi == "X") {
         return "<p>★40厘米处符合Percival法则(P<=0)</p>" +
            "①40厘米处的BO模糊点(%.2f△)范围大于等于BI模糊点(%@△)的一半"
      }
      bi = bi!=='X'?bi:0
      bo = bo!=='X'?bo:0
      bo > bi?arr = [bo, bi]:arr = [bi, bo]
      let p = ((arr[0]*(1/3))-(arr[1]*(2/3))).toFixed(2)
      if (p > 0) {
         return "<p>★40厘米处不符合Percival法则(P>0)</p>" +
             `<p>①根据公式P=1/3G-2/3L计算，P=1/3×${arr[0]}-2/3×${arr[1]},P(分解)=${p}BO</p>`
      }else {
        return "<p>★40厘米处符合Percival法则(P<=0)</p>" +
            "<p>40厘米处的BO模糊点(%.2f△)范围大于等于BI模糊点(%@△)的一半</p>"
      }
    }

  },

  mounted() {
    document.addEventListener('click', this.getXY, false)
    // mobileSelect2.show()
  },

  methods: {
    save() {
      let lg = this.$loading({
        background: 'rgba(0, 0, 0, 0.8)',
        text: '保存中...'
      })
      let datas = JSON.parse(JSON.stringify(this.$store.state.eyeTest.ViewFnTest))
      for (let k in datas.S_OP_Json) {
        datas.S_OP_Json[k] = datas.S_OP_Json[k].toString()
      }
      datas.S_OP_Json = this._jmjson(datas.S_OP_Json)

      if ( this.$store.state.Time.timeObj.EC_eyesPosition.ENTER != 0) {
        let times = ((new Date()).valueOf() - this.$store.state.Time.timeObj.EC_eyesPosition.ENTER)/1000 + Number(this.$store.state.Time.timeObj.EC_eyesPosition.EXIT)
        this._api.publicApi.addTime('EC_eyesPosition,EC_eyesAdjust,EC_eyesFusional,EC_VFConclusion', times)
        this.jsTime('EC_eyesPosition', 1)
      }

      this._http.post(this._getUrl('S_ALL_Normal_Save'), {
        "token": {
          "TimeSpan": 1566827733,
          "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: datas
      })
      .then(res => {
        lg.close()
        if (res.Msg == 'Success') {
          this.$store.commit('isbc/uptjk', {
            val: true,
            keys: 'sgnjc'
          })
          this.$alert('保存成功!', '提示')
          this.$store.commit('eyeTest/upViewFnTest', JSON.parse(JSON.stringify(this.postData)))
        }else {
          this.$alert('保存失败!', '提示')
        }
      })
    },

    zh(key) {
      if (Number(this.postData.S_OP_Json[key]) > 0 && this.postData.S_OP_Json[key].toString().indexOf('+')==-1) {
        this.postData.S_OP_Json[key] = "+" + this.postData.S_OP_Json[key]
      }else {
        this.postData.S_OP_Json[key] = this.postData.S_OP_Json[key] !== ''?this.postData.S_OP_Json[key].toString():''
      }
    },

    xzsx(className, data) {

    },

    sxbh(picker, values) {
      this.postData.S_OP_Json[this.sxdqkey] = values.join('')
    },

    handleClick() {

    },

    getpgjg() {
      this.clshow = true
      let str = this.$store.state.eyeTest.ViewFnTest.S_OP_Json.S_OP_VF_Diagnosis
      this.pgjg = str?str.split(','):[]
      // console.log(this.pgjg)
    },

    cshTime(keys) {
      // console.log(this.$store.state.Time.timeObj[keys].EXIT)
      this.$store.commit('Time/upTimeObj', {
        keys: keys,
        val: {
          ENTER: (new Date()).valueOf(),
          EXIT: this.$store.state.Time.timeObj[keys].EXIT
        }
      })
    },
    jsTime(keys,types=0) {
      if (types) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: 0,
            EXIT: 0
          }
        })
        return
      }
      if (this.$store.state.Time.timeObj[keys].ENTER !== 0) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: (new Date()).valueOf(),
            EXIT: ((new Date()).valueOf() - this.$store.state.Time.timeObj[keys].ENTER)/1000 + Number(this.$store.state.Time.timeObj[keys].EXIT)
          }
        })
      }
    },

    keyd(e) {
      // e.returnValue= false
    },
    getgb(str, key, iszf) {
      // console.log(e)
      // document.activeElement.blur();
      this.keyval = str
      this.srval = key
      this.issr = true
      this.iszf = iszf
    },

    getXY(e) {
      if (this.issr) {
        this.$store.commit('isShow/upkeyh', e.y)
        this.showkeys = true
        this.issr = false
      }else  {
        this.showkeys = false
        this.srval = ''
      }

    },

    changeval(e) {
      this.keyval = e
      this.$set(this.postData.S_OP_Json, this.srval, e)
      if (this.postData.S_OP_Json[this.srval] && !isNaN(Number(this.postData.S_OP_Json[this.srval])) && this.iszf) {
        if (this.postData.S_OP_Json[this.srval] && Number(this.postData.S_OP_Json[this.srval]) > 0 && this.postData.S_OP_Json[this.srval].indexOf('+') < 0  && this.postData.S_OP_Json[this.srval].indexOf('-') < 0) {
          this.$set(this.postData.S_OP_Json, this.srval, '+'+e)
        }
      }
    }
  },


  components: {
    CorrelationTest: () => import("@/components/specialSurveys/ViewFunctionTest/correlationTest"),
    ViewFnAssess: () => import("@/components/specialSurveys/ViewFunctionTest/ViewFnAssess"),
    // XZCJ: () => import("../../components/xzcj"),
    [Picker.name]: Picker,
    CLZJ: () => import("@/components/specialSurveys/ViewFunctionTest/clzj")
  },

  beforeDestroy() {
    this.jsTime('EC_eyesPosition')
    document.removeEventListener('click', this.getXY)
  }
}
</script>

<style scoped lang="scss">
  .ViewFunctionTest {
    width: 90vw;
    padding-left: 10vw;
    height: 70vh;
    overflow-y: auto;
    padding-bottom: 5vh;
  }

  .cwcolor {color: #ff0101}

  .padd-40 {padding-top: 40px;}
  .border-b {border-bottom: 1px solid #000000 !important;}
  .top-a {
    display: flex;
    justify-content: start;
    width: 100%;
    li {
      width: 12vw;
      text-align: center;
      border: 1px solid #000000;
      border-right: none;
      height: 40px;
      border-bottom: none;
      display: flex;
      align-items: center;
      justify-content: center;
      input {
        border: none;
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
        outline: none;
      }
      &:last-child {
        border-right: 1px solid #000000;
      }
    }
    .fx {
      input {
        width: 20%;
      }
    }
    .wadd {
      width: calc(10vw + 1px);
    }
  }
  .top-b {
    display: flex;
    align-items: center;
    justify-content: start;
    .hbl {
      width: calc(24vw + 1px);
      height: 81px;
      border: 1px solid #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        width: 10vw;
        text-align: right;
      }
      input {
        width: 10vw;
        border: none;
        outline: none;
      }
    }
    .noneborder{border-right: none;}
  }
  .min {
    display: flex;
    justify-content: start;
    align-items: center;
    li {
      width: 5vw;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #000000;
      border-right: none;
      border-bottom: none;
      input {
        display: block;
        width: 70%;
        height: 100%;
        border: none;
        outline: none;
      }
      &:nth-child(1) {width: 12vw;}
      &:nth-child(7) {border-right: 1px solid #000000;}
    }
  }
  .bottoms {
    display: flex;
    justify-content: start;
    .bottoms-r-a {
      display: flex;
      justify-content: start;
      align-items: flex-end;
      input {
        display: block;
        border: none;
        border-bottom: 1px solid #000000;
        width: 5vw;
        margin-left: 10px;
        outline: none;
        text-align: center;
      }
    }
    .bottoms-r-b {
      margin-top: 20px;
      display: flex;
      justify-content: start;
      .bottoms-r-content {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 20px;
        input {
          display: block;
          width: 5vw;
          border: none;
          border-bottom: 1px solid #000000;
          margin: 0 10px;
          outline: none;
          text-align: center;
        }
        p {width: 30px; position: relative; top: 4px}
        .zj {width: 6vw}
      }
    }
  }
  ::v-deep .bottoms  .l-zs{border: none; border-radius: 0;height: 1.2em; line-height: normal;}
  ::v-deep .bottoms  .l-sr{border: none; border-radius: 0; display: none;}

  .fn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8vh;
    li {margin-right: 20px}
  }


  .hdxz {
    display: flex;
    justify-content: center;
    height: 140px;
    .a1,.a2,.a3 {
      height: 100%;
      overflow: hidden;
      //overflow-y: auto;
      padding: 5px;
    }
    .a2 {margin: 0 20px}
  }

  .fx-box {
    height: 55vh;
    overflow-y: auto;
  }

  .gsbox {
    padding-left: 30px;
    padding-top: 30px;
  }

  .Morgan {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      width: 25%;
      border-bottom: 1px solid #7f7e7e;
      border-top: 1px solid #7f7e7e;
      height: 30px;
      line-height: 30px;
    }
    //li {border-right: 1px solid #7f7e7e;}
    //li:nth-child(4) {border-right: none;}
  }

  .Morgan-content {
    height: 48vh;
    overflow-y: auto;
    li {border-top: none}
  }
  .Morgan-content ul li {
    border-right: 1px solid #7f7e7e;
    &:nth-child(4) {border-right: none;}
  }


  ::v-deep .fx .el-select__caret {
    display: none !important;
  }
  ::v-deep .fx .el-input__inner{
    padding-right: 0!important;
    padding-left: 0!important;
    text-align: center;
  }


  ::v-deep .zj  .el-input__inner {
    border: none !important;
  }
  ::v-deep .top-a  .el-input__inner {
    border: none !important;
  }
  ::v-deep .min  .el-input__inner {
    border: none !important;
    padding: 0 0 0 0.05rem !important;
  }
  ::v-deep .fx  .el-button{
    border: none;
  }
  ::v-deep  .van-picker__toolbar {
    display: none !important;
  }

  ::v-deep  .el-button {
    width: calc(10vw + 1px);
    border: none;
  }
  ::v-deep .MEM .el-button {
    width: 1.5rem;
  }
  ::v-deep .NPC .el-button {
    border-bottom: 1px solid #000000;
    border-radius: 0;
    margin-bottom: 2px;
  }
  ::v-deep .OU .el-button {
    border-bottom: 1px solid #000000;
    border-radius: 0;
    width: 1.1rem;
  }
  ::v-deep .fn .el-button {
    border: 1px solid #cbcaca;
  }
  ::v-deep .fp .el-dialog {
    margin-top: -30px !important;
  }
  ::v-deep .xz input {background: #e8e6e6}
</style>
